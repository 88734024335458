import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BituminousApplicationsRepository extends BaseRepository {
    baseUrl = 'management/bituminous-mixtures/bituminous-applications';

    /**
     * Returns all records for current specification
     *
     * @returns {*}
     */
    allBySector(criteria) {
        return this.httpClient.post('management/applications/all-by-sector', criteria);
    }
}
